<template>
  <education-section
    :key="updateKey"
    :educations="educations"
    @setPage="getEducations"
  />
</template>
<script>
import { EducationSection } from "@/components/contact/sections";
import { initialListingModel } from "@/utils";
import { talentClient } from "@/api";
export default {
  name: "ContactEducationsPage",
  components: {
    EducationSection,
  },
  data() {
    return {
      educations: initialListingModel(10, { ordering: "-updated_at" }),
      updateKey: 0,
    };
  },
  metaInfo() {
    return {
      title: `Карточка контакта / Образование`,
    };
  },
  computed: {
    talentId() {
      return +this.$route.params.id;
    },
  },
  activated() {
    this.getEducations(this.educations.page);
    this.updateKey += 1;
  },
  methods: {
    async getEducations(page = 1) {
      if (this.educations.pending) return;
      const { talentId } = this;
      this.educations.pending = true;
      this.educations.error = "";
      try {
        const { data } = await talentClient({
          method: "GET",
          url: `/users/${talentId}/educations/`,
          params: {
            limit: this.educations.limit,
            offset: (page - 1) * this.educations.limit,
            ...this.educations.params,
          },
        });
        this.educations = {
          ...this.educations,
          list: data.results,
          total: data.count,
          pagesCount: Math.ceil(data.count / this.educations.limit),
          page,
        };
      } catch (error) {
        this.educations.error = error.message;
      }
      this.educations.pending = false;
    },
  },
};
</script>
